import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  message: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: "10rem",
    color: "red",
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));

const SignUp = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>✖</div>
      <Typography variant="h5" className={classes.message}>
        Para adicionar novos atendentes acesse Atendentes logado no WaSap com uma conta de Administrador.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        component={RouterLink}
        to="/login"
      >
        Voltar para página de login
      </Button>
    </div>
  );
};

export default SignUp;
